import React from 'react';

function Prestations() {
  return (
    <div className="font-comfortaa space-y-4">
      <h1 className="text-center text-lg md:text-2xl uppercase font-bold border-pinky border-2 p-2">
        prestations
      </h1>
      <div className="px-4">
        <h3>
          création d'un pitch / conférence avec propositions de
          plusieurs structures afin de s'adapter à votre authenticité
        </h3>
        <div>
          <ul className="list-disc px-3">
            <li>brief (1h)</li>
            <li>proposition finale (1j de travail)</li>
          </ul>
          <p className="text-center font-bold py-2">Prix : 350€</p>
        </div>
        <div>
          <ul className="list-disc px-3">
            <li>entraînement (1h x2)</li>
            <li>
              coaching sur la forme pour renforcer sa prise de parole
              (2h)
            </li>
          </ul>
          <p className="text-center font-bold py-2">Prix : 400€</p>
        </div>
      </div>
    </div>
  );
}

export default Prestations;
