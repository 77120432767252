import React from 'react';

const Publics = () => {
  return (
    <div className="font-comfortaa py-6 px-3 md:px-0 text-md">
      <ul className="space-y-2">
        <li>Vos idées sont confuses et désorganisées?</li>
        <li>Vous n'êtes pas clair ni synthétique?</li>
        <li>Vous ne vous sentez pas écouté(e)?</li>
        <li>
          Vous avez peur d'échanger avec votre boss? Avec un
          journaliste? De parler en public?
        </li>
        <li>
          Vous ne savez pas comment défendre une idée, un projet?
        </li>
        <li>
          Vous n'êtes plus connecté(e) à vos équipes? Il n'y a plus de
          cohésion ?
        </li>
        <li>Vous souhaitez être un.e bon leader(euse)?</li>
        <li>Vous souhaitez inspirer les personnes autour de vous?</li>
      </ul>
    </div>
  );
};

export default Publics;
