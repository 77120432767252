import { Link } from 'react-router-dom';
function MailtoButton() {
  return (
    <div className="border-pinky border-2 p-6 rounded-lg">
      <Link
        target="_blank"
        to={{
          pathname:
            'mailto:formation@conversari.fr?subject=Formation%20Conversari%20Accompagnement',
        }}
      >
        <p className="text-center hover:text-amethyst-500">
          Réserver un accompagnment
        </p>
      </Link>
    </div>
  );
}

export default MailtoButton;
