const AdultFormule = () => {
  return (
    <div className="rounded-lg bg-atoll-100 font-comfortaa">
      <h1 className="py-8 text-2xl text-center border-pinky-500 border-2 rounded-xl px-12">
        Adultes
      </h1>
      <h2 className="py-3 text-center">
        <ul>
          <li>Chef(fe)s d'entreprise</li>
          <li>Manager</li>
          <li>Chef(fe)s d'équipe</li>
          <li>Avocat(e)s</li>
          <li>Élu(e)s</li>
        </ul>
      </h2>
    </div>
  );
};

export default AdultFormule;
