import React from 'react';
import Accompagnement from '../cards/businessPageCards/Formules/Accompagnement';
import Interventions from '../cards/businessPageCards/Formules/Interventions';
import Parcours from '../cards/businessPageCards/Formules/Parcours';
import Prestations from '../cards/businessPageCards/Formules/Prestations';

const Offres = () => {
  return (
    <div className="items-center md:justify-center">
      <div className="items-center  md:flex md:space-x-7 md:py-12 md:justify-center">
        <div className="p-12 w-96 md:h-[52rem]">
          <div className="space-y-6">
            <Accompagnement />
            <Prestations />
          </div>
        </div>
        <div className="p-12 md:border-l-2 md:border-r-2 border-gray-800 w-96 md:h-[52rem]">
          <Parcours />
        </div>
        <div className="p-12  w-96 md:h-[52rem]">
          <Interventions />
        </div>
      </div>
    </div>
  );
};

export default Offres;
