import mener from '../../../assets/Images/Ponctuation/Mener-transparent.png';

const Structuration = () => {
  return (
    <div className="space-y-4 md:w-72 h-[200] font-comfortaa">
      <div className="p-6 border-gray-800 border-2">
        <img src={mener} alt="ponctuation" className="w-64" />
      </div>
      <div>
        <h2 className="mx-auto uppercase font-bold text-lg md:text-2xl text-center border-pinky border-2 p-1">
          la structuration de la pensée
        </h2>
      </div>
      <ul className="list-disc space-y-4 py-6">
        <li>
          Pour organiser ses idées, les clarifier et prendre des
          décisions
        </li>
        <li>
          Pour préparer un entretien, une interview, un oral, une
          conférence
        </li>
        <li>
          Pour défendre éfficacement une idée, une thèse ou un projet
        </li>
        <li>
          Pour sortir de la confusion, d'une situation bloquante, pour
          trouver des solutions
        </li>
      </ul>
    </div>
  );
};

export default Structuration;
