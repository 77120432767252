import React from 'react';
import image from '../assets/Images/LogoConversari-MiniFINAL-V2.jpg';
import { data } from '../data/testimonials/dataTesti';

const Testimonials = () => {
  return (
      <div className='py-24 space-y-6 md:flex md:space-y-0'>
        {data.map((p, i) =>
        <div key={i} className="items-center justify-center h-full px-5 py-5 md:flex font-comfortaa">
          <div
              className="w-full max-w-xl px-5 pt-5 pb-10 mx-auto text-gray-800 rounded-lg shadow-lg bg-gray-50 dark:bg-gray-800 dark:text-gray-50">
            <div className="w-full pt-1 pb-5 mx-auto -mt-16 text-center">
              <div className="relative block">
                <img alt="profile" src={image} className="object-cover w-20 h-20 mx-auto rounded-full "/>
              </div>
            </div>
            <div className="w-full mb-10">
              <div className="h-3 text-3xl leading-tight text-left text-amethyst-500">
                “
              </div>
              <p className="px-5 text-sm text-center text-gray-600 dark:text-gray-100">
                {p.text}
              </p>
              <div className="h-3 -mt-3 text-3xl leading-tight text-right text-amethyst-500">
                ”
              </div>
            </div>
            <div className="w-full">
              <p className="font-bold text-center text-md text-amethyst-500">
                {p.name}
              </p>
              <p className="text-xs text-center text-gray-500 dark:text-gray-300">
                {p.title}
              </p>
            </div>
          </div>
        </div>
        )}
      </div>

  );
};

export default Testimonials;
