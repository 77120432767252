import argumenter from '../../../assets/Images/Ponctuation/argumenter-transparent.png';

const Intelligence = () => {
  return (
    <div className="space-y-4 font-comfortaa md:w-72">
      <div className="p-6 border-gray-800 border-2">
        <img src={argumenter} alt="ponctuation" className="w-64" />
      </div>
      <div>
        <h2 className="mx-auto uppercase font-bold text-lg md:text-2xl text-center border-pinky border-2 p-1">
          l'intelligence <br /> collective
        </h2>
      </div>
      <ul className="list-disc space-y-4">
        <li>Pour renforcer la cohésion de ses équipes</li>
        <li>
          Pour constituer un environment favorable à un échange apaisé
          et une écoute active
        </li>
        <li>
          Pour proposer des concertations encadrées, dynamiques et
          engageantes
        </li>
        <li>
          Pour favoriser la collaboration, la coopération et gagner en
          efficacité
        </li>
      </ul>
    </div>
  );
};

export default Intelligence;
