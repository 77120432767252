export const data = [
  {
    id: 1,
    image: '',
    text: 'Avec Conversari j’ai pu bénéficier d’un entretien en tete à tête avec Delphine Schram. Ensemble nous avons préparé mon premier entretien universitaire.\n' +
        '\n' +
        'Attentive à mes besoins, Delphine a pu cibler mes objectifs et m’a aidée à les réaliser avec sérieux et méthode.\n' +
        '\n' +
        'Je n’avais jamais passé d’entretien mais grâce à la formation, j’ai pu prendre confiance en mes capacités. Le bilan est très positif et j’ai eu la satisfaction de voir que ma candidature avait été retenue.',
    name: 'Chloé',
    title: 'élève de terminale',
  },
  {
    id: 2,
    image: '',
    text: 'J\'ai traversé une période très difficile de perdition lors de la rédaction de ma thèse. J\'avais perdu confiance dans mon organisation et mes idées et c\'est grâce à Delphine que je me suis littéralement "remise en scelle". Grâce à sa bienveillance et différentes techniques de travail, j\'ai pu reprendre confiance et appréhender avec beaucoup plus de sérénité ma rédaction. Le besoin d\'un oeil extérieur est souvent capital pour avancer et Delphine a su l\'incarner avec humanité et professionalisme.',
    name: 'Marie D.',
    title: 'doctorante en anthropologie sociale et ethnologie, EHESS-IIAC',
  },
  {
    id: 3,
    image: '',
    text: 'Ce qui m’a vraiment aidé à me préparer c’est l’écoute de Delphine  sur le projet dans sa globalité et dans les petits détails. À partir de là  elle a su me guider sur ce qu’était l’essentiel de mon projet, pour ensuite m’aider à construire la meilleure formulation possible de mon discours. Ce qui a vraiment réussi parce qu’il est devenu facile pour moi de le délivrer au jury de manière percutante',
    name: 'LK. Imany',
    title: 'artiste d\'art visuel et autrice',
  },
  {
    id: 4,
    image: '',
    text: 'Delphine m’a aidée à structurer mon activité professionnelle et à lui donner une ligne directrice qui me guide et m’aide à rester connectée à ma mission. Elle a été disponible, à l’écoute et son accompagnement a été le tremplin dont j’avais besoin pour passer à l’étape suivante dans mon activité. \n',
    name: 'Virginie V.',
    title: 'accompagnatrice en amour de soi, inspiratrice et chanteuse',
  }
]


