import ListenArt from '../cards/businessPageCards/Offres/ListenArt';
import Structuration from '../cards/businessPageCards/Offres/Structuration';
import Intelligence from '../cards/businessPageCards/Offres/Intelligence';

const BusinessCards = () => {
  return (
    <div className="items-center px-24 align-middle md:justify-center">
      <div className="items-center align-middle md:flex md:space-x-7 md:py-12 md:justify-center space-y-4 md:space-y-0">
        <div className="md:p-12 md:border-pinky md:border-2">
          <Structuration />
        </div>
        <div className="md:p-12 md:border-pinky md:border-2">
          <ListenArt />
        </div>
        <div className="md:p-12 border-pinky md:border-2">
          <Intelligence />
        </div>
      </div>
    </div>
  );
};

export default BusinessCards;
