import React from 'react';

const Accompagnement = () => {
  return (
    <div className="font-comfortaa space-y-4">
      <h1 className="text-center text-lg md:text-2xl uppercase font-bold border-pinky border-2 p-2">
        accompagnement ponctuel
      </h1>
      <div className="px-4">
        <ul>
          <li className="italic font-bold">1h30</li>
          <li>Temps d'écoute</li>
          <li>Temps d'échange</li>
          <li>
            Piste d'actions concrètes à mettre en place aussitôt
          </li>
        </ul>
      </div>
      <ul>
        <li>
          <p className="text-center font-bold py-2">
            Prix : 100€ / 70€ (étudiant(e)s)
          </p>
        </li>
      </ul>
    </div>
  );
};

export default Accompagnement;
