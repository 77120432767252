import React from 'react';

function Interventions() {
  return (
    <div className="font-comfortaa space-y-4">
      <h1 className="text-center text-lg md:text-2xl uppercase font-bold border-pinky border-2 p-2">
        interventions
      </h1>
      <div className="px-4">
        <ul>
          <li className="italic font-bold">
            Programme sur plusieurs jours
          </li>
          <li>Préparation (sur mesure)</li>
          <li>Animations</li>
          <li>Mise à disposition d'outils</li>
          <li>Encadrement (sur mesure)</li>
        </ul>
      </div>
      <p className="text-center font-bold py-2">Prix : Sur devis</p>
      <ul className="text-center uppercase space-y-2">
        <li className="border-amethyst border-2 p-2">
          Intervention <br /> "Structurer sa pensée"
        </li>
        <li className="border-amethyst border-2 p-2">
          Intervention <br /> "L'art d'être écouté(e)"
        </li>
        <li className="border-amethyst border-2 p-2">
          Intervention <br /> "Intelligence collective"
        </li>
      </ul>
    </div>
  );
}

export default Interventions;
