const StudentFormule = () => {
  return (
    <div className="rounded-lg bg-atoll-100 font-comfortaa">
      <h1 className="py-8  text-2xl text-center border-pinky-500 border-2 rounded-xl md:px-6">
        Étudiant(e)s
      </h1>
      <h2 className="md:py-12 py-6 text-center">
        <ul>
          <li>Étudiant(e)s</li>
          <li>Thésard(e)s</li>
        </ul>
      </h2>
    </div>
  );
};

export default StudentFormule;
