import ecoute from '../../../assets/Images/Ponctuation/Ecoute-transparent.png';

const ListenArt = () => {
  return (
    <div className="space-y-4 md:w-72 h-[200] font-comfortaa">
      <div className="p-6 border-gray-800 border-2">
        <img src={ecoute} alt="ponctuation" className="w-[52]" />
      </div>
      <div>
        <h2 className="mx-auto uppercase font-bold text-lg md:text-2xl text-center border-pinky border-2 p-1">
          l'art d'être <br /> écouté(e)
        </h2>
      </div>
      <ul className="list-disc space-y-4">
        <li>
          Pour proposer un discours innovant, construit sous le prisme
          de celles et ceux qui l'écoutent
        </li>
        <li>
          Pour renforcer ses liens avec ses partenaires et ses clients
          et gagner en performance
        </li>
        <li>Pour ne plus avoir peur de la prise de parole</li>
        <li>
          Pour inspirer les personnes autour de vous, transmettre
          votre passion et vos idées
        </li>
      </ul>
    </div>
  );
};

export default ListenArt;
