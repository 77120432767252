import React from 'react';
import StudentFormule from '../cards/businessPageCards/Formules/StudentFormule';
import BusinessFormule from '../cards/businessPageCards/Formules/BusinessFormule';
import AdultFormule from '../cards/businessPageCards/Formules/AdultFormule';

const FormulaCards = () => {
  return (
    <>
      <div className="items-center py-12 align-middle md:flex space-y-7 md:space-y-0 md:space-x-24 md:justify-center">
        <StudentFormule />
        <AdultFormule />
        <BusinessFormule />
      </div>
    </>
  );
};

export default FormulaCards;
