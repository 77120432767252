import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Formation from './pages/Formation';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Formation} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
