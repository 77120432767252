import React from "react";
import favicon from "../../src/assets/Images/LogoConversari-MiniFINAL-V2.jpg";

const EntrepriseDesc = () => {
  return (
    <div className='grid justify-center items-center md:px-96 font-comfortaa relative min-w-full max-w-full'>
      <div className='md:flex bg-white w-full'>
        <img
          className='md:w-72 md:h-72'
          src={favicon}
          alt='Logo conversari'
        />
        <div className='space-y-5'>
          <h1 className='md:text-5xl text-2xl text-center text-amethyst-500 pt-12 px-4'>
            "Don't raise your voice, improve your argument."*
          </h1>
          <p className='md:text-1xl text-lg text-black text-center'>
            <span>Desmond Tutu</span>, discours adressé à la Nelson Mandela Foundation à
            Houghton, Johannesburg, Afrique du Sud, 23 novembre 2004
            <br /> *"N'élevez pas la voix, améliorez votre argumentation”
          </p>
        </div>
      </div>
      <div className='bg-white py-6 space-y-5'>
        <p className='md:px-12 md:py-24 md:text-4xl text-1xl px-3 text-center'>
          Conversari propose des accompagnements individuels, ateliers pratiques
          et formations collectives pour sortir de situations bloquantes,
          confuses, et exprimer sa voi(e)x face aux autres avec justesse.
        </p>
        <p className='md:px-24 md:text-4xl text-1xl px-3 text-center'>
          Faciliter l’expression de sa pensée, l’écoute et l’échange pour
          permettre la rencontre, la coopération et <br />
          l’harmonie collective
          <br />
          (savoir communiquer).
        </p>
      </div>
    </div>
  );
};

export default EntrepriseDesc;
