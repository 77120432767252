import { useState } from 'react';
import imageBg from '../assets/Images/iStock-resized.png';
import Logo from '../assets/Images/Logo3.png';
import EntrepriseDesc from '../components/EntrepriseDesc';
import FormulaCards from '../components/FormulaCards';
import Testimonials from '../components/Testimonials';
import BusinessCards from '../components/BusinessCards';
import Publics from '../components/Publics';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import Offres from '../components/Offres';
import CalendyButton from '../cards/businessPageCards/Buttons/CalendyButton';
import MailtoButton from '../cards/businessPageCards/Buttons/MailtoButton';

const Formation = () => {
  const [showScroll, setShowScroll] = useState(false);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener('scroll', checkScrollTop);
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <div className="w-full h-full bg-gray-50">
      <header className="relative flex md:bg-pinky-100 md:h-screen overflow-hidden">
        <Link
          to={{ pathname: 'https://conversari.fr' }}
          target="_blank"
        >
          <img
            className="absolute w-80 h-24 top-10 left-6"
            src={Logo}
            alt=""
          />
        </Link>

        <img className="bg-cover w-full" src={imageBg} alt="" />
      </header>
      <section className="bg-atoll-100 h-full w-full min-w-full ">
        <div className="md:-mt-40 min-w-full">
          <EntrepriseDesc />
        </div>
        <div className="md:px-64 md:py-24">
          <h1 className="font-comfortaa p-2 text-4xl lg:text-6xl md:w-64 md:h-18 px-4 self-center border-b-8 border-pinky-500">
            Qui ?
          </h1>
          <Publics />
        </div>
        <div className="align-middle justify-items-center px-4 md:px-0 max-w-6xl mx-auto">
          <FormulaCards />
        </div>
        <div className="md:px-64 md:py-24">
          <h1 className="font-comfortaa p-2 text-4xl lg:text-6xl md:w-64 md:h-18 px-4 self-center border-b-8 border-pinky-500">
            Quoi ?
          </h1>
        </div>
        <div className="font-comfortaa px-6 lg:px-64 py-4">
          <h3 className="font-bold text-lg">EXPRESSION*</h3>
          <p className="md:w-3/6 w-full text-sm">
            En latin “expressio”, en français, il s’agit d’exprimer
            quelque chose par le langage ou une technique artistique;
            force ou vitalité qu’un artiste insuffle à une œuvre
            d’art; ensemble de signes extérieurs qui traduisent un
            sentiment, une émotion.
          </p>
        </div>
        <div className="align-middle justify-items-center">
          <BusinessCards />
        </div>
        <div className="md:px-64 md:py-24">
          <h1 className="font-comfortaa py-12 p-2 text-4xl lg:text-6xl md:w-3/7  md:h-18 px-4 self-center border-b-8 border-pinky-500">
            Comment ?
          </h1>
        </div>
        <div className="align-middle justify-items-center">
          <Offres />
        </div>
        <div className="md:px-64 md:py-24">
          <h1 className="font-comfortaa p-2 text-4xl lg:text-6xl md:w-3/7  md:h-18 px-4 self-center border-b-8 border-pinky-500">
            C'est parti!
          </h1>
          <div className="w-full md:py-12 py-4 px-24 space-x-6 md:px-0 align-middle md:flex space-y-7 md:space-y-0 md:justify-center font-comfortaa">
            <CalendyButton />
            <MailtoButton />
          </div>
        </div>
        <div className="flex align-middle justify-items-center font-comfortaa space-x-6"></div>
      </section>
      <section className="md:absolute bg-pinky-100 w-full">
        <div className="w-full bg-gray-50 md:px-64 py-24 my-8">
          <h1 className="font-comfortaa p-2 text-4xl lg:text-6xl md:w-3/7 h-18 px-4 self-center border-b-8 border-pinky-500">
            Témoignages
          </h1>
        </div>
        <div>
          <Testimonials />
        </div>
        <Footer />
      </section>

      <div className="fixed bottom-0 right-0 p-2 mb-6 mr-6 bg-opacity-25 rounded md:right-0 bg-amethyst-400 hover:bg-amethyst-500 hover:bg-opacity-40">
        <svg
          className="w-6 h-6 cursor-pointer scrollTop"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          onClick={scrollTop}
        >
          <path
            fillRule="evenodd"
            d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
};

export default Formation;
